
#nav {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 8rem;
	background-color: var(--color-blue-dark);
	padding: 0 2rem;

	display: flex;
	justify-content: space-between;
	align-items: center;

	z-index: 999;

	& > .logo {
		margin: auto;

		img {
			max-width: 15.3rem;
		}

		//h1 {
		//	display: inline-block;
		//	line-height: 2.4rem;
		//	padding: 0.8rem 1.5rem;
		//	border-radius: 10rem;
		//
		//	&.blue {
		//		background-color: var(--color-package-blue);
		//	}
		//	&.red {
		//		background-color: var(--color-package-red);
		//	}
		//	&.silver {
		//		background-color: var(--color-package-silver);
		//	}
		//}
	}

	.right, .left {
		position: relative;
	}

	.header {
		font-size: 2.2rem;
		line-height: 2.6rem;
		color: #FFFFFF;
	}
	.icon {
		fill: #FFFFFF;
		width: 2rem;
		height: 2rem;
		cursor: pointer;
	}

	.cart-count {
		pointer-events: none;
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 1.7rem;
		height: 1.7rem;
		top: 0;
		right: -1rem;
		font-size: 1rem;
		line-height: 1.2rem;
		font-weight: var(--font-weight-bold);
		color: #FFFFFF;
		background-color: var(--color-red);
		border: 0.2rem solid var(--color-blue-dark);
		border-radius: 10rem;
	}
}
