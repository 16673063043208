:root {
  --break-point-mobile: "(max-width: 600px)";
  --break-point-tablet-portrait: "(max-width: 768px)";
  --break-point-tablet-landscape: "(max-width: 1024px)";
  --break-point-desktop: "(min-width: 1024px)";
}

@include break-point(desktop) {
  .mobile {
    display: none !important;
  }
  .tablet-portrait {
    display: none !important;
  }
  .tablet-landscape {
    display: none !important;
  }
  .desktop {
    display: initial !important;
  }
}

@include break-point(tablet-landscape) {
  .desktop {
    display: none !important;
  }
  .mobile {
    display: none !important;
  }
  .tablet-portrait {
    display: none !important;
  }
  .tablet-landscape {
    display: initial !important;
  }
}

@include break-point(tablet-portrait) {
  .tablet-landscape {
    display: none !important;
  }
  .desktop {
    display: none !important;
  }
  .mobile {
    display: none !important;
  }
  .tablet-portrait {
    display: initial !important;
  }
}

@include break-point(mobile) {
  .tablet-portrait {
    display: none !important;
  }
  .tablet-landscape {
    display: none !important;
  }
  .desktop {
    display: none !important;
  }
  .mobile {
    display: initial !important;
  }
}
