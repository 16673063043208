html {
    font-size: 62.5%;
}

body {
    background-color: var(--color-bg);
    font-family: var(--font-family);
    font-weight: var(--font-weight-medium);
    font-size: 14px;
    color: var(--color-grey-dark);

	@include break-point(tablet-portrait) {
		min-width: 100%;
	}
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1 {
	font-weight: var(--font-weight-black);
	font-size: 2.4rem;
	line-height: 2.9rem;
}

span, label {
    font-weight: inherit;
}

a:link, a:visited {
    text-decoration: none;
}

svg {
    width: 100%;
	max-width: 100%;
	max-height: 100%;
}

.uppercase {
    text-transform: uppercase;
}

.container {
    padding: 2.5rem;
    max-width: 100%;
}

.regular {
    font-weight: var(--font-weight-regular);
}
.medium {
    font-weight: var(--font-weight-medium);
}
.bold {
    font-weight: var(--font-weight-bold);
}

.error {
	text-align: center;
	color: var(--color-red);
	font-size: 1.4rem;
	margin-bottom: 4rem;
}

[class*=grid-layout-] {
    display: grid;

    &.grid-layout-2 {
      grid-template-columns: 1fr 1fr;
    }
    &.grid-layout-3 {
      grid-template-columns: 1fr 1fr 1fr;
    }
    &.grid-layout-4 {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    &.gutter-10 {
      column-gap: 1rem;
    }
}


[class*=flex-] {
    display: flex;

    &[class*=flex-end] {
        justify-content: flex-end;
    }
    &[class*=flex-start] {
        justify-content: flex-start;
    }
    &[class*=flex-center] {
        justify-content: center;
    }
    &[class*=-space-between] {
        justify-content: space-between;
    }
    &[class*=-space-around] {
        justify-content: space-around;
    }
    &[class*=-row] {
        flex-direction: row;
    }
    &[class*=-column] {
        flex-direction: column;
    }
}

.align-start {
    align-items: start;
}

.align-end {
    align-items: end;
}

.align-center {
    align-items: center;
}

.align-self-start {
    align-self: start;
}

.align-self-end {
    align-self: end;
}

.align-self-center {
    align-self: center;
}

.justify-start {
    justify-self: start;
}

.justify-end {
    justify-self: end;
}

.justify-center {
    justify-self: center;
}
