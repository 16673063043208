@mixin gradientAccentBorder($offset: false) {
	@if $offset == false {
		$offset: -0.1rem;
	}
	&::after {
		content: "";
		position: absolute;
		top: $offset;
		right: $offset;
		bottom: $offset;
		left: $offset;
		background: var(--gradient-accent);
		z-index: -1;
		border-radius: 1rem;
	}
}

@mixin hoverGradient() {
	background-color: #11141D;
	border: 0.1rem solid transparent;
	.icon {
		fill: url(#OrangeGradient);
	}
	span, i {
		background: var(--gradient-accent);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
	&::after{
		position: absolute;
		top: -0.2rem; bottom: -0.2rem;
		left: -0.2rem; right: -0.2rem;
		background: var(--gradient-accent);
		content: '';
		z-index: -1;
		border-radius: inherit;
	}
}

@mixin ellipse() {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

@mixin placeholder() {
	::-webkit-input-placeholder {
		@content;
	}

	::-moz-placeholder {
		@content;
	}

	:-ms-input-placeholder {
		@content;
	}

	:-moz-placeholder {
		@content;
	}
}

@mixin break-point($point) {

	$mobile: "(max-width: 600px)";
	$tablet-portrait: "(max-width: 768px)";
	$tablet-landscape: "(max-width: 1024px)";
	$desktop: "(min-width: 1024px)";

	@if $point == desktop {
		@media #{$desktop} {
			@content;
		}
	} @else if $point == tablet-landscape {
		@media #{$tablet-landscape} {
			@content;
		}
	} @else if $point == tablet-portrait {
		@media #{$tablet-portrait} {
			@content;
		}
	} @else if $point == mobile {
		@media #{$mobile}  {
			@content;
		}
	}

}

// @include position($position, $top $right $bottom $left)
// shorthand include for setting position properties
@mixin position($position: relative, $coordinates: null null null null) {

	@if type-of($position) == list {
		$coordinates: $position;
		$position: relative;
	}

	$coordinates: unpack($coordinates);

	$offsets: (
			top: nth($coordinates, 1),
			right: nth($coordinates, 2),
			bottom: nth($coordinates, 3),
			left: nth($coordinates, 4)
	);

	position: $position;

	@each $offset, $value in $offsets {
		@if is-length($value) {
			#{$offset}: $value;
		}
	}
}

// Convert shorthand to the 4-value syntax
@function unpack($shorthand) {
	@if length($shorthand) == 1 {
		@return nth($shorthand, 1) nth($shorthand, 1) nth($shorthand, 1) nth($shorthand, 1);
	} @else if length($shorthand) == 2 {
		@return nth($shorthand, 1) nth($shorthand, 2) nth($shorthand, 1) nth($shorthand, 2);
	} @else if length($shorthand) == 3 {
		@return nth($shorthand, 1) nth($shorthand, 2) nth($shorthand, 3) nth($shorthand, 2);
	} @else {
		@return $shorthand;
	}
}
