@keyframes shake {
	10%, 90% {
		transform: translate3d(-1px, 0, 0);
	}

	20%, 80% {
		transform: translate3d(2px, 0, 0);
	}

	30%, 50%, 70% {
		transform: translate3d(-4px, 0, 0);
	}

	40%, 60% {
		transform: translate3d(4px, 0, 0);
	}
}

@keyframes tap {
	0% {
		transform: scale(0);
		opacity: 1;
	}

	10% {
		transform: scale(1.2);
	}
	100% {
		transform: scale(0.5);
		opacity: 0;
	}
}
