:root {
    --color-bg:	                  #FAFAFA;
    --color-blue-dark:	          #011624;
    --color-blue-light:           #28BBF3;
    --color-blue-medium:          #0A6D9E;
    --color-grey-dark:            #384250;
    --color-grey-medium:          #BEC5D2;
	--color-grey-medium-2:          #8794AA;
	--color-grey-light:           #E1E7ED;
	--color-grey-light-half:      #{rgba(#E1E7ED, 0.2)};
    --color-red:        		  #C80000;
    --color-red-rgb:       		  200,0,0;
    --color-off-white:        	  #EEF1F5;
    --color-overlay:              #{rgba(#566996, 0.2)};
	--color-orange:               #FFAA00;
	--color-green:               #2AB27B;

	--color-package-red: 			#C90C0F;
	--color-package-blue: 			#00A6E2;
	--color-package-silver: 		silver;

    --font-family: 			'Afrihost Sans', Arial, Helvetica, sans-serif;
	--font-weight-light: 	300;
	--font-weight-book:		normal;
	--font-weight-regular:	normal;
	--font-weight-medium: 	500;
	--font-weight-bold: 	700;
    --font-weight-black: 	900;
}
